import { Injectable, signal } from "@angular/core";
import { Params } from "@angular/router";
import { ReportService } from "app/+reporting/services/report.service";
import _, { chain, isArray, isEmpty, isEqual, isObject, reduce, sortBy } from "lodash";
import moment from "moment";
import { GridDataSourceBuilder } from "rl-common/components/grid/datasource/builders/grid-datasource-builder";
import { CharTypeId, SystemIndicators } from "rl-common/consts";
import { ICharacteristic } from "rl-common/models/i-characteristic";
import { ICharacteristicData } from "rl-common/models/i-characteristic-data";
import { ICharacteristicMetaDataCollection } from "rl-common/models/i-characteristic-meta-data-collection";
import { ICharacteristicMetaDataValue } from "rl-common/models/i-characteristic-meta-data-value";
import { IEntitySearchDoc } from "rl-common/models/i-entity-search-doc";
import { IHierarchyLabelContext } from "rl-common/models/i-hierarchy-label-context";
import { IQueryNode } from "rl-common/models/i-query-node";
import { IRecordTitle } from "rl-common/models/i-record-title";
import { ISearchRequestOptions } from "rl-common/models/i-search-request-options";
import { CompanyService } from "rl-common/services/company/company.service";
import { ITemplateCharData } from "rl-common/services/entity/models/i-template-char-data";
import { IGridViewColumn } from "rl-common/services/grid-view/models/i-grid-view-column";
import { OneConfigService } from "rl-common/services/one-config/one-config.service";
import { ProgressService } from "rl-common/services/progress.service";
import { DateLocaleType, DateUtil } from "rl-common/utils";
import { LovUtil } from "rl-common/utils/lov-util";
import { Observable, of } from "rxjs";
import { finalize, map, switchMap, tap } from "rxjs/operators";
import { SearchDocViewColumnStrategy } from "../../../common/components/grid/datasource/columns/search-doc-view-column-strategy";
import { AdvancedCriteriaState, AvailabilityDisplayCriteriaState, AvailsSnapshot, AvailStatusIds, CatalogCriteriaState, CatalogLovCriteriaState, CriteriaState, DatesCriteriaState, DimCriteriaState, ExclCriteriaState, ICatalogCriteria, IRightsExplorerCriteria, IRightsExplorerCriteriaRequest, RightsActionCondition, RightsActionConditionCriteriaState, RightsDimensionIds, RightsDisplayCriteriaState, RightsExplorerCriteriaType, RightsHistoryCriteriaState, RightsSummaryTemplateGroupCriteriaState, RightsTempletCriteriaState as RightsTemplateCriteriaState, RollupCriteriaState, UnavailReasonIds, WindowingCriteriaState } from "./rights-explorer.models";


type RightsExplorerQueryParams = {
	[prop in keyof IRightsExplorerCriteriaRequest | "dim1" | "dim2" | "dim3" | "dim4" | "reportType" | "viewType"]: string
};

type Kvp = [number, string];

/** keys for smaller json serialization */
type RacAbbr = "u" | "t" | "d" | "w";

const racStringifyMap: Record<keyof RightsActionCondition, RacAbbr> = {
	showDimLimitation: "d",
	showTermLimitation: "w",
	templateIds: "t",
	unavailableReasonId: "u"
};

const racParseMap: Record<RacAbbr, keyof RightsActionCondition> = {
	d: "showDimLimitation",
	w: "showTermLimitation",
	t: "templateIds",
	u: "unavailableReasonId"
}

const racReplacer = (key, value) => {
	if (!isArray(value) && isObject(value)) {
		return Object.keys(value).reduce((acc, k) => {
			const newKey = racStringifyMap[k] ?? k;
			acc[newKey] = value[k];
			return acc;
		}, {});
	}
	return value;
}

const racReviver = function (key, value) {
	if (!isArray(this) && isObject(this)) {
		// Replace the key according to the map
		const newKey = racParseMap[key] || key; // Use the mapped name if it exists, otherwise keep the original
		// Use an object to return a single property replacement
		if (key !== "") {
			this[newKey] = value;
			return undefined;
		}
	}
	return value;
};

@Injectable()
export class RightsExplorerService {


	dimChars: _.Dictionary<ICharacteristic> = {};
	dimLovs: _.Dictionary<ICharacteristicMetaDataValue[]> = {};
	dimSelectedIds: _.Dictionary<number[]> = {};
	dimSelectedLabels: _.Dictionary<string[]> = {};
	catalogLovs: ICatalogCriteria[];
	initialized = false;

	public rhColumnStrategy: SearchDocViewColumnStrategy<IEntitySearchDoc> = null;
	private rightsetTemplateLovs: ICharacteristicMetaDataValue[] = null;
	private rightsetTemplateHLC: IHierarchyLabelContext = null;

	private dimLabelCache = new Map<string, string[]>();

	// default Rightset History Tempaltes
	defaultRightsetHistoryTemplates: number[] = null;
	defaultRightsActionCondition: RightsActionCondition[] = null;
	defaultRightsSummaryChips: number = null;
	subgridMode = signal<"action" | "history">("history");

	reasonKvps: Kvp[] = [
		[UnavailReasonIds.LimitedByClearance, "Limited By Clearance"],
		[UnavailReasonIds.RightsOut, "Rights Out Exists"],
		[UnavailReasonIds.NoRightsIn, "No Rights In"],
		[UnavailReasonIds.NotWindowed, "Not Windowed"],
		[UnavailReasonIds.LimitedByChildrenNoRightsIn, "Limited By Children (No Rights In)"],
		[UnavailReasonIds.LimitedByChildren, "Limited By Children"],
		[UnavailReasonIds.LimitedByOverrun, "Limited By Overrun"],
		[UnavailReasonIds.InsufficientReapply, "Insufficient Reapply"]
	];

	constructor(
		private _reportService: ReportService,
		private _progressService: ProgressService,
		private readonly _companyService: CompanyService,
		private _oneConfigService: OneConfigService,
		private _gridDataSourceBuilder: GridDataSourceBuilder,
		private _oneConfig: OneConfigService) {

		this.rhColumnStrategy = this._gridDataSourceBuilder.columnStrategies.searchDocColumnStrategy(CharTypeId.Right);
	}

	async initialize() {
		if (!this.initialized) {
			this._progressService.startProgress();

			const [drht, drac, drsc] = await Promise.all([
				this._companyService.getRightsHistoryDefault().toPromise(),
				this._companyService.getRightsActionConditionDefault().toPromise(),
				this._companyService.getRightsSummaryChipsDefault().toPromise()
			])

			// const drht = await ;
			this._progressService.endProgress();
			if (drht?.length > 0) {
				this.defaultRightsetHistoryTemplates = drht;
			}
			if (drac?.length > 0) {
				this.defaultRightsActionCondition = drac;
			}
			if (drsc) {
				this.defaultRightsSummaryChips = drsc;
			}
			this.initialized = true;
		}
	}

	buildRightsTemplateGroupLovs(): ICharacteristicMetaDataValue[] {
		const rightsetTemplates = this._oneConfigService.getTemplates(CharTypeId.Right);

		const rightsetTemplateGroups = chain(rightsetTemplates.map(t => [t.templateGroupID, t.templateGroup] as [number, string]))
			.sortBy(t => t[0])
			.uniqBy(t => t[0])
			.value();

		return rightsetTemplateGroups
			.map(t => {
				const templates = rightsetTemplates
					.filter(t2 => t2.templateGroupID === t[0]);
				templates.sort((a, b) => a.sequenceNumber - b.sequenceNumber);

				if (templates?.length > 0) {
					return {
						characteristicValueID: t[0] * -1, // do this do this to prevent mixing up with tempalteIds
						characteristicValueLabel: `${t[1]} Templates`,
						sequenceNumber: t[0],
						statusId: 1,
						childValues: templates.map(t2 => ({
							characteristicValueID: t2.templateID,
							characteristicValueLabel: t2.templateName,
							sequenceNumber: t2.sequenceNumber,
							statusId: 1,
							childValues: []
						} as ICharacteristicMetaDataValue))
					} as ICharacteristicMetaDataValue;
				}
				return null;
			})
			.filter(t => t != null);
	}

	getOtherCriteria() {
		const rightsetTemplates = this._oneConfigService.getTemplates(CharTypeId.Right);

		this.rightsetTemplateLovs = this.buildRightsTemplateGroupLovs();
		this.rightsetTemplateHLC = LovUtil.buildHierarchyLabelContext(this.rightsetTemplateLovs);

		const rightsDims = this._oneConfigService.getRightsDimensions();
		const dimIds = RightsDimensionIds.filter(dimId => rightsDims.characteristics[dimId]);
		const dimLabels = dimIds.reduce<_.Dictionary<string>>((acc, i) => {
			acc[i] = rightsDims.characteristics[i].label;
			return acc;
		}, {});

		const output: IRightsExplorerCriteria<CriteriaState>[] = [
			{
				key: "avail_display",
				type: RightsExplorerCriteriaType.availabilityDisplay,
				label: "Availability Display",
				getValue: (req) => {
					const showAvailable = req.showAvailable || req.showPartial;
					return of([showAvailable, req.showPartial, req.showUnavailable]);
				},
				setValue: (req, values) => {
					req.showAvailable = values[0] || values[1];
					req.showPartial = values[1];
					req.showUnavailable = values[2];
				}
			} as IRightsExplorerCriteria<AvailabilityDisplayCriteriaState>,
			{
				key: "exclusion",
				type: RightsExplorerCriteriaType.rightsDisplay,
				label: "Exclusion Display",
				getValue: (req) => {
					const dimFormat: _.Dictionary<boolean> = dimIds.reduce((acc, dimId) => {
						acc[dimId] = ((1 << dimId) & req.dimFormatFlag) !== 0;
						return acc;
					}, {});

					return of([dimIds, dimLabels, dimFormat]);
				},
				setValue: (req, values) => {
					const _dimIds = RightsDimensionIds.filter(dimId => rightsDims.characteristics[dimId]);
					const dimFormatFlag = _dimIds.reduce(
						(acc, dimId) => {
							if (values[2][dimId]) {
								acc |= (1 << dimId);
							}
							return acc;
						}, 0);

					req.dimFormatFlag = dimFormatFlag;
				}
			} as IRightsExplorerCriteria<RightsDisplayCriteriaState>,
			{
				key: "windowing",
				type: RightsExplorerCriteriaType.windowing,
				label: "Windowing",
				getValue: (req) => of([req.isWindowed]),
				setValue: (req, state) => req.isWindowed = state[0]
			} as IRightsExplorerCriteria<WindowingCriteriaState>,
			{
				key: "rightset_history_templates",
				type: RightsExplorerCriteriaType.rightsHistoryTemplates,
				label: "Rights History",
				getValue: (req) => {

					const obs: Observable<unknown> = this.ensureRHGridViews$(req.rightsHistoryViewId);
					return obs.pipe(switchMap(() => of([req.rightsHistoryViewId, req.rightsetHistoryTemplateIds ?? [], this.rightsetTemplateLovs])));
				},
				setValue: (req, state) => {
					if (state[1]?.length > 0) {
						req.rightsetHistoryTemplateIds = state[1];
					} else {
						delete req.rightsetHistoryTemplateIds;
					}
					if (state[0] != null) {
						req.rightsHistoryViewId = state[0];
					} else {
						delete req.rightsHistoryViewId;
					}
				}
			} as IRightsExplorerCriteria<RightsHistoryCriteriaState>,
			{
				key: "rights_actions_conditions",
				type: RightsExplorerCriteriaType.rightsActions,
				label: "Rights Actions Conditions",
				getValue: (req) => {
					// make display versions of the row
					const displayValues: [string, string, string, string][] = [];
					if (req.rightsActionConditions?.length > 0) {
						for (const rac of req.rightsActionConditions) {
							const { selectedBranchValueIds } = LovUtil.rectifySelectedValueIds(rac.templateIds, this.rightsetTemplateHLC);
							displayValues.push([
								this.reasonKvps.find(kvp => kvp[0] === rac.unavailableReasonId)?.[1],
								selectedBranchValueIds.map(id => this.rightsetTemplateHLC.valueIdLabelMap.get(id)).join(", "),
								rac.showDimLimitation ? "Yes" : "No",
								rac.showTermLimitation ? "Yes" : "No",
							]);
						}
					}


					return of([req.rightsActionConditions, displayValues]);
				},
				setValue: (req, state) => {
					if (state[0]?.length > 0) {
						req.rightsActionConditions = state[0];
					} else {
						delete req.rightsActionConditions;
					}
				}
			} as IRightsExplorerCriteria<RightsActionConditionCriteriaState>,
			{
				key: "rights_summary_template_group",
				type: RightsExplorerCriteriaType.rightsSummaryTemplateGroups,
				label: "Rights Summary Template Group",
				getValue: (req) => of([req.summaryTemplateGroupCountType ?? 0]),
				setValue: (req, state) => {
					req.summaryTemplateGroupCountType = state[0];
				}
			} as IRightsExplorerCriteria<RightsSummaryTemplateGroupCriteriaState>,
			{
				key: "rights_templates",
				type: RightsExplorerCriteriaType.rightsTemplates,
				label: "Rights Templates (Timeline)",
				getValue: (req) => of([req.rightSetTemplateIds ?? [], rightsetTemplates]),
				setValue: (req, state) => {
					if (state[0]?.length > 0) {
						req.rightSetTemplateIds = state[0];
					} else {
						req.rightSetTemplateIds = null;
					}
				}
			} as IRightsExplorerCriteria<RightsTemplateCriteriaState>,
			{
				key: "advanced",
				type: RightsExplorerCriteriaType.advanced,
				label: "Scope Dimensions to Search",
				getValue: (req) => of([
					(req.nonScopedFlags & 1 << 0) === 0,
					(req.nonScopedFlags & 1 << 1) === 0,
					(req.nonScopedFlags & 1 << 2) === 0,
					(req.nonScopedFlags & 1 << 3) === 0,
					(req.nonScopedFlags & 1 << 4) === 0,
					dimLabels
				]),
				setValue: (req, values) => {
					req.nonScopedFlags = 0;
					for (let i = 0; i < 5; i++) {
						if (!values[i]) {
							req.nonScopedFlags |= (1 << i);
						}
					}
				}
			} as IRightsExplorerCriteria<AdvancedCriteriaState>
		];

		return output;
	}

	getRightsetCriteria() {
		const rightsDims = this._oneConfigService.getRightsDimensions();

		RightsDimensionIds.forEach(i => {
			if (rightsDims.lovs[i]) {
				this.dimChars[i] = rightsDims.characteristics[i];
				this.dimLovs[i] = rightsDims.lovs[i].listOfValues;
			}
		});
		const getDimIds = (req: IRightsExplorerCriteriaRequest, dimId: number) => {
			if (req && req.dimIds) {
				return req.dimIds[dimId];
			}
			return [];
		};
		const setDimIds = (req: IRightsExplorerCriteriaRequest, dimId: number, valueIds: number[]) => {
			if (req) {
				if (!req.dimIds) {
					req.dimIds = {};
				}
				req.dimIds[dimId] = valueIds;
			}
		};
		const lovCriteria = RightsDimensionIds
			.filter((i) => rightsDims.characteristics[i])
			.map((i) => ({
				key: `dim_${i}`,
				type: RightsExplorerCriteriaType.lov,
				label: rightsDims.characteristics[i].label,
				getValue: (req) =>
					of([getDimIds(req, i), rightsDims.lovs[i].listOfValues, rightsDims.lovs[i].charValueGroups, rightsDims.lovs[i].charValueGroupAssociations]),
				setValue: (req, state) => setDimIds(req, i, state[0])
			} as IRightsExplorerCriteria<DimCriteriaState>));


		const defaultCollapseSequence = this.getDefaultCollapseSequence();
		const defaultCollapseSequenceDimIds = defaultCollapseSequence.map(kv => kv[0]);

		const output: IRightsExplorerCriteria<CriteriaState>[] = [
			{
				key: "dates",
				type: RightsExplorerCriteriaType.dates,
				label: "Dates",
				getValue: (req) => of([req.startDate, req.endDate, req.minDays, req.matchType, req.truncateWindows]),
				setValue: (req, state) => {
					req.startDate = state[0];
					req.endDate = state[1];
					req.minDays = state[2];
					req.matchType = state[3];
					req.truncateWindows = state[4];
				},
				validate: (state) => {
					const errors: { dates?: boolean } = {};
					const startDate = DateUtil.parseToUTCMoment(state[0], DateLocaleType.Storage);
					const endDate = DateUtil.parseToUTCMoment(state[1], DateLocaleType.Storage);

					if (startDate.isValid() && endDate.isValid() && startDate.isAfter(endDate)) {
						errors.dates = true;
					}
					return errors;
				}
			} as IRightsExplorerCriteria<DatesCriteriaState>,
			{
				key: "excl",
				type: RightsExplorerCriteriaType.exclusivity,
				label: "Exclusivity",
				getValue: (req) => of([req.isExclusive ?? true]),
				setValue: (req, state) => {
					req.isExclusive = state[0];
				}
			} as IRightsExplorerCriteria<ExclCriteriaState>,
			{
				key: "rollup",
				type: RightsExplorerCriteriaType.rollup,
				label: "Rollup",
				getValue: (req) => of([req.rollup]),
				setValue: (req, state) => req.rollup = state[0]
			} as IRightsExplorerCriteria<RollupCriteriaState>,
			...lovCriteria
		];
		return output;
	}

	getCatalogCriteria() {

		const setValue = (req: IRightsExplorerCriteriaRequest, solrLabel: string, selectedObject: _.Dictionary<boolean>) => {
			if (!req.catalogFacets) {
				req.catalogFacets = {};
			}
			req.catalogFacets[solrLabel] = reduce(selectedObject, (acc, selected, key) => {
				if (selected) {
					acc.push(key);
				}
				return acc;
			}, []);
		};

		return this._reportService.getCatalogLovs()
			.pipe(
				tap((lovs) => this.catalogLovs = lovs),
				map((lovs) => {
					const templateLov = lovs.filter(x => x.solrLabel === "template_meta");
					const statusLov = lovs.filter(x => x.solrLabel === "status_meta");
					lovs = lovs.filter(x => x.solrLabel !== "template_meta" && x.solrLabel !== "status_meta");
					lovs.sort((a, b) => a.label.trim() > b.label.trim() ? 1 : -1);
					lovs.unshift(statusLov[0]);
					lovs.unshift(templateLov[0]);

					const output: IRightsExplorerCriteria<CriteriaState>[] = [
						{
							key: "catalog",
							type: RightsExplorerCriteriaType.catalog,
							label: "Select Catalog",
							getValue: (req) => {
								if (req?.catalogIds?.length > 0) {
									return this._reportService.getCatalogTitles(req.catalogIds);
								}

								return of([]);
							},
							setValue: (req, values) => {
								if (req) {
									req.catalogIds = values.map(r => r.recordId);
								}
							}
						} as IRightsExplorerCriteria<CatalogCriteriaState>,
						...lovs
							.filter(lov => !!lov)
							.map((lov) => {
								const label = lov.label;
								const solrLabel = lov.solrLabel;
								return {
									key: `lov_${lov.solrLabel}`,
									label: lov.label,
									type: RightsExplorerCriteriaType.multiSelect,
									getValue: (req) => {
										const selectedValues = (req != null && req.catalogFacets ? req.catalogFacets[solrLabel] : []) || [];
										const optionsObject: _.Dictionary<string> = lov.options.reduce((acc, option) => {
											acc[option.value] = option.label; return acc;
										}, {});
										const selectedObject: _.Dictionary<boolean> = selectedValues.reduce((acc, value) => {
											acc[value] = true; return acc;
										}, {});
										const isAllSelected = lov.options.every(opt => selectedValues.includes(opt.value));

										return of([label, optionsObject, selectedObject, isAllSelected]);
									},
									setValue: (req, values) => setValue(req, lov.solrLabel, values[2])
								} as IRightsExplorerCriteria<CatalogLovCriteriaState>;
							})];
					return output;
				})
			);
	}

	ensureRHGridViews$(viewId: number): Observable<unknown> {
		let obs: Observable<unknown> = null;
		if (this.rhColumnStrategy.gridViews$.value == null) {
			obs = this.rhColumnStrategy.fetchGridViews();
		} else {
			obs = of(null);
		}
		return obs.pipe(
			tap(() => {
				const foundView = viewId != null ? this.rhColumnStrategy.gridViews$.value.find(v => v.gridViewId === viewId) : this.rhColumnStrategy.gridViews$.value.find(v => v.isDefault);
				if (foundView != null) {
					this.rhColumnStrategy.setView(foundView);
				}
			})
		);
	}

	getCatalogTitles(catalogIds: number[]): Observable<IRecordTitle[]> {
		if (catalogIds.length > 0) {
			return this._reportService.getCatalogTitles(catalogIds);
		} else {
			return of([]);
		}
	}

	searchTimeline(model: IRightsExplorerCriteriaRequest, offset: number, limit: number): Observable<string> {
		if (!model) {
			return of<string>(null);
		}
		return this._reportService.getRightsExplorerTimeline(
			model.startDate,
			model.endDate,
			model.dimIds[1],
			model.dimIds[2],
			model.dimIds[3],
			model.dimIds[4],
			model.catalogIds,
			model.rightSetTemplateIds,
			model.isExclusive,
			model.isWindowed,
			model.showAvailable,
			model.showPartial,
			model.showUnavailable,
			model.minDays,
			model.matchType,
			model.catalogFacets,
			model.dimFormatFlag,
			offset,
			limit
		);
	}

	getDimLabels(dimId: number, valueIds: number[]): string[] {
		const key = `${dimId}_${valueIds}`;
		if (!this.dimLabelCache.has(key)) {
			const lovs = this.dimLovs[dimId];
			if (lovs) {
				const labels = LovUtil.rectifySelectedLovs(lovs, valueIds).map(lov => lov.characteristicValueLabel);
				this.dimLabelCache.set(key, labels);
			}
		}
		return this.dimLabelCache.get(key) || [];
	}


	getDefaultCriteriaRequest(): IRightsExplorerCriteriaRequest {

		const today = moment().startOf("day");
		const startDate = today.format("L");
		const endDate = today.add(6, "month").format("L");

		return {
			startDate,
			endDate,
			catalogFacets: {},
			catalogIds: [],
			dimIds: {},
			isWindowed: false,
			matchType: 1,
			rightSetTemplateIds: [],
			isExclusive: true,
			minDays: null,
			rollup: null,
			dimFormatFlag: (1 << 1) | (1 << 2) | (1 << 3) | (1 << 4), // all the flags set
			dimShowFlag: (1 << 0) | (1 << 1) | (1 << 2) | (1 << 3) | (1 << 4), // all the flags set
			truncateWindows: false,
			showAvailable: true,
			showPartial: true,
			showUnavailable: false,
			nonScopedFlags: 0,
			collapseSequence: null,
			rightsetHistoryTemplateIds: this.defaultRightsetHistoryTemplates ?? [],
			rightsHistoryViewId: null,
			rightsActionConditions: this.defaultRightsActionCondition,
			summaryTemplateGroupCountType: this.defaultRightsSummaryChips ?? 0 // history
		};
	}

	getCriteriaRequestFromQueryParams(params: Params): IRightsExplorerCriteriaRequest {
		const qp = params as RightsExplorerQueryParams;
		const defaults = this.getDefaultCriteriaRequest();

		let startDate = defaults.startDate;
		if (qp["startDate"]) {
			const startMoment = moment(qp["startDate"], DateUtil.SOLR_DATE_FORMAT);
			if (startMoment.isValid()) {
				startDate = startMoment.format(DateUtil.getDateFormat(DateLocaleType.Storage));
			}
		}

		let endDate = defaults.endDate;
		if (qp["endDate"]) {
			const endMoment = moment(qp["endDate"], DateUtil.SOLR_DATE_FORMAT);
			if (endMoment.isValid()) {
				endDate = endMoment.format(DateUtil.getDateFormat(DateLocaleType.Storage));
			}
		}

		const dimIds: _.Dictionary<number[]> = {};
		if (qp["dim1"]) {
			dimIds[1] = (qp["dim1"] as string).split(",").map(Number);
		}
		if (qp["dim2"]) {
			dimIds[2] = (qp["dim2"] as string).split(",").map(Number);
		}
		if (qp["dim3"]) {
			dimIds[3] = (qp["dim3"] as string).split(",").map(Number);
		}
		if (qp["dim4"]) {
			dimIds[4] = (qp["dim4"] as string).split(",").map(Number);
		}

		const parseQPInt = (k: keyof IRightsExplorerCriteriaRequest): number => {
			if (qp[k] != null) {
				return parseInt(qp[k] as string);
			}
			return (defaults[k] as number);
		};

		const parseIntArr = (k: keyof IRightsExplorerCriteriaRequest): number[] => {
			if (qp[k] != null) {
				return (qp[k] as string).split(",").map(Number);
			}
			return (defaults[k] as number[]);
		};

		const parseBool = (k: keyof IRightsExplorerCriteriaRequest): boolean => {
			if (qp[k] != null) {
				return JSON.parse(qp[k]);
			}
			return (defaults[k] as boolean);
		};

		const parseString = (k: keyof IRightsExplorerCriteriaRequest): string => {
			if (qp[k] != null) {
				return qp[k];
			}
			return defaults[k] as string;
		}
		const output: IRightsExplorerCriteriaRequest = {
			startDate,
			endDate,
			catalogFacets: JSON.parse(qp["catalogFacets"] ?? null) ?? defaults.catalogFacets,
			catalogIds: parseIntArr("catalogIds"),
			dimIds,
			isWindowed: parseBool("isWindowed"),
			matchType: parseQPInt("matchType"),
			rightSetTemplateIds: parseIntArr("rightSetTemplateIds"),
			isExclusive: parseBool("isExclusive"),
			minDays: parseQPInt("minDays"),
			rollup: parseQPInt("rollup"),
			dimFormatFlag: parseQPInt("dimFormatFlag"),
			dimShowFlag: parseQPInt("dimShowFlag"),
			truncateWindows: parseBool("truncateWindows"),
			showAvailable: parseBool("showAvailable"),
			showPartial: parseBool("showPartial"),
			showUnavailable: parseBool("showUnavailable"),
			nonScopedFlags: parseQPInt("nonScopedFlags"),
			collapseSequence: parseIntArr("collapseSequence"),
			rightsetHistoryTemplateIds: this.defaultRightsetHistoryTemplates ?? parseIntArr("rightsetHistoryTemplateIds"),
			rightsHistoryViewId: parseQPInt("rightsHistoryViewId"),
			rightsActionConditions: this.defaultRightsActionCondition ?? JSON.parse(qp["rightsActionConditions"] ?? null, racReviver),
			summaryTemplateGroupCountType: this.defaultRightsSummaryChips ?? parseQPInt("summaryTemplateGroupCountType")
		};

		return output;
	}




	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	racJsonReplacer = (key: any, value: any) => {
		if (!isArray(value) && isObject(value)) {
			const value2 = value as RightsActionCondition;
			return {
				u: value2.unavailableReasonId,
				t: value2.templateIds,
				d: value2.showDimLimitation,

			} as Record<RacAbbr, unknown>;
		}
		return value;
	};

	getQueryParamsFromCriteriaRequest(req: IRightsExplorerCriteriaRequest, includeDefaults: boolean = false): Params {
		const qp = {} as RightsExplorerQueryParams;
		const defaults = this.getDefaultCriteriaRequest();

		Object.keys(req).forEach((k: keyof IRightsExplorerCriteriaRequest) => {
			if (req[k] != null && (includeDefaults || !isEqual(req[k], defaults[k]))) {
				switch (k) {
					case "startDate":
					case "endDate":
						const date = DateUtil.parseToUTCMoment(req[k], DateLocaleType.Storage);
						if (date.isValid()) {
							qp[k] = date.format(DateUtil.SOLR_DATE_FORMAT);
						}
						break;
					case "dimIds":
						const dimIds = req[k];
						if (dimIds[1]?.length > 0) {
							qp["dim1"] = dimIds[1].join(",");
						}
						if (dimIds[2]?.length > 0) {
							qp["dim2"] = dimIds[2].join(",");
						}
						if (dimIds[3]?.length > 0) {
							qp["dim3"] = dimIds[3].join(",");
						}
						if (dimIds[4]?.length > 0) {
							qp["dim4"] = dimIds[4].join(",");
						}
						break;
					case "catalogFacets":
						if (!isEmpty(req.catalogFacets)) {
							qp[k] = JSON.stringify(req.catalogFacets);
						}
						break;
					case "collapseSequence":
						const defaultCollapseSequence = this.getDefaultCollapseSequence().map(kv => kv[0]);
						if (!isEqual(req.collapseSequence, defaultCollapseSequence)) {
							qp[k] = req.collapseSequence.join(",");
						}
						break;
					case "rightsActionConditions":
						if (isArray(req.rightsActionConditions) && req.rightsActionConditions.length > 0) {
							qp["rightsActionConditions"] = JSON.stringify(req.rightsActionConditions, racReplacer);
						}
						break;
					default:
						const value = req[k];
						if (Array.isArray(value)) {
							if (value.length > 0) {
								qp[k] = value.join(",");
							}
						} else if (typeof value === "boolean") {
							qp[k] = value.toString();
						} else if (value != null && value !== undefined) {
							qp[k] = value.toString();
						}
						break;
				}
			}
		});

		// if (this.defaultRightsetHistoryTemplates?.length > 0) {
		// 	qp["rightsetHistoryTemplateIds"] = this.defaultRightsetHistoryTemplates.join(",");
		// }

		return qp as Params;
	}

	getDefaultCollapseSequence(): [number, string][] {
		const rightsDims = this._oneConfigService.getRightsDimensions();
		const dimIds = sortBy(RightsDimensionIds.filter(dimId => rightsDims.characteristics[dimId]), d => -1 * d);
		const defaultCollapseSequence: [number, string][] = dimIds.map(d => [d, rightsDims.characteristics[d].label] as [number, string]);
		defaultCollapseSequence.push([0, "Time"]);
		return defaultCollapseSequence;
	}

	getAvailsList(model: IRightsExplorerCriteriaRequest, gridViewColumns: IGridViewColumn[], offset: number, limit: number): Observable<string> {
		if (!model) {
			return of<string>(null);
		}
		// this._progressService.startProgress();
		return this._reportService.getAvailsList(
			model.startDate,
			model.endDate,
			model.dimIds[1],
			model.dimIds[2],
			model.dimIds[3],
			model.dimIds[4],
			model.nonScopedFlags,
			model.isExclusive,
			model.isWindowed,
			model.minDays,
			model.matchType,
			model.catalogIds,
			model.rightSetTemplateIds,
			model.truncateWindows,
			model.rollup,
			model.catalogFacets,
			model.dimFormatFlag,
			model.showAvailable,
			model.showPartial,
			model.showUnavailable,
			model.collapseSequence,
			model.rightsetHistoryTemplateIds,
			model.summaryTemplateGroupCountType,
			model.rightsActionConditions,
			gridViewColumns,
			offset,
			limit
		);
	}

	getAvailsSummaryList(model: IRightsExplorerCriteriaRequest, gridViewColumns: IGridViewColumn[], offset: number, limit: number): Observable<string> {
		if (!model) {
			return of<string>(null);
		}
		// this._progressService.startProgress();
		return this._reportService.getAvailsSummaryList(
			model.startDate,
			model.endDate,
			model.dimIds[1],
			model.dimIds[2],
			model.dimIds[3],
			model.dimIds[4],
			model.nonScopedFlags,
			model.isExclusive,
			model.isWindowed,
			model.minDays,
			model.matchType,
			model.catalogIds,
			model.rightSetTemplateIds,
			model.truncateWindows,
			model.rollup,
			model.catalogFacets,
			model.dimFormatFlag,
			model.showAvailable,
			model.showPartial,
			model.showUnavailable,
			model.collapseSequence,
			model.rightsetHistoryTemplateIds,
			model.summaryTemplateGroupCountType,
			model.rightsActionConditions,
			gridViewColumns,
			offset,
			limit
		);
	}

	exportAvailsList(model: IRightsExplorerCriteriaRequest, gridViewColumns: IGridViewColumn[], rightsetHistoryGridViewColumns: IGridViewColumn[], isSummaryReport: boolean, exportFormat: string, reportUrl: string) {
		if (!model) {
			return of<void>(null);
		}
		this._progressService.startProgress();
		return this._reportService.exportAvailsList(
			model.startDate,
			model.endDate,
			model.dimIds[1],
			model.dimIds[2],
			model.dimIds[3],
			model.dimIds[4],
			model.nonScopedFlags,
			model.isExclusive,
			model.isWindowed,
			model.minDays,
			model.matchType,
			model.catalogIds,
			model.rightSetTemplateIds,
			model.truncateWindows,
			model.rollup,
			model.catalogFacets,
			model.dimFormatFlag,
			model.showAvailable,
			model.showPartial,
			model.showUnavailable,
			model.collapseSequence,
			gridViewColumns,
			rightsetHistoryGridViewColumns,
			model.rightsetHistoryTemplateIds,
			model.summaryTemplateGroupCountType,
			model.rightsActionConditions,
			isSummaryReport,
			exportFormat,
			reportUrl
		)
			.pipe(
				finalize(() => this._progressService.endProgress())
			);
	}

	getCatalogList(model: IRightsExplorerCriteriaRequest, gridViewColumns: IGridViewColumn[], query: IQueryNode, searchOptions: ISearchRequestOptions, offset: number, limit: number): Observable<string> {
		return this._reportService.getCatalogList(
			model.startDate,
			model.endDate,
			model.dimIds[1],
			model.dimIds[2],
			model.dimIds[3],
			model.dimIds[4],
			model.isExclusive,
			model.isWindowed,
			model.minDays,
			model.matchType,
			model.showAvailable,
			model.showPartial,
			model.showUnavailable,
			model.catalogIds,
			model.rightSetTemplateIds,
			model.catalogFacets,
			query,
			searchOptions,
			gridViewColumns,
			offset,
			limit
		);
	}

	exportCatalogList(model: IRightsExplorerCriteriaRequest, gridViewColumns: IGridViewColumn[], query: IQueryNode, searchOptions: ISearchRequestOptions, exportFormat: string, reportUrl: string) {
		this._progressService.startProgress();
		return this._reportService.exportCatalogList(
			model.startDate,
			model.endDate,
			model.dimIds[1],
			model.dimIds[2],
			model.dimIds[3],
			model.dimIds[4],
			model.isExclusive,
			model.isWindowed,
			model.minDays,
			model.matchType,
			model.showAvailable,
			model.showPartial,
			model.showUnavailable,
			model.catalogIds,
			model.rightSetTemplateIds,
			model.catalogFacets,
			query,
			searchOptions,
			gridViewColumns,
			exportFormat,
			reportUrl
		)
			.pipe(
				finalize(() => this._progressService.endProgress())
			);
	}

	addCatalogListToWorksheet(
		worksheetId: string,
		isAllSelected: boolean,
		addRecordIds: number[],
		exceptRecordIds: number[],
		model: IRightsExplorerCriteriaRequest,
		query: IQueryNode,
		searchOptions: ISearchRequestOptions): Observable<string> {
		this._progressService.startProgress();
		return this._reportService.addCatalogListToWorksheet(
			worksheetId,
			isAllSelected,
			addRecordIds,
			exceptRecordIds,
			model.startDate,
			model.endDate,
			model.dimIds[1],
			model.dimIds[2],
			model.dimIds[3],
			model.dimIds[4],
			model.isExclusive,
			model.isWindowed,
			model.minDays,
			model.matchType,
			model.showAvailable,
			model.showPartial,
			model.showUnavailable,
			model.catalogIds,
			model.rightSetTemplateIds,
			model.catalogFacets,
			query,
			searchOptions
		)
			.pipe(
				finalize(() => this._progressService.endProgress())
			);
	}

	getDealList(model: IRightsExplorerCriteriaRequest, gridViewColumns: IGridViewColumn[], query: IQueryNode, searchOptions: ISearchRequestOptions, offset: number, limit: number): Observable<string> {
		return this._reportService.getDealList(
			model.startDate,
			model.endDate,
			model.dimIds[1],
			model.dimIds[2],
			model.dimIds[3],
			model.dimIds[4],
			model.catalogIds,
			model.rightSetTemplateIds,
			model.catalogFacets,
			query,
			searchOptions,
			gridViewColumns,
			offset,
			limit
		);
	}

	exportDealList(model: IRightsExplorerCriteriaRequest, gridViewColumns: IGridViewColumn[], query: IQueryNode, searchOptions: ISearchRequestOptions, reportUrl: string, exportFormat: string): Observable<string> {
		return this._reportService.exportDealList(
			model.startDate,
			model.endDate,
			model.dimIds[1],
			model.dimIds[2],
			model.dimIds[3],
			model.dimIds[4],
			model.catalogIds,
			model.rightSetTemplateIds,
			model.catalogFacets,
			query,
			searchOptions,
			gridViewColumns,
			reportUrl,
			exportFormat
		);
	}

	getRightHistoryTemplateLabels(templateIds: number[]): string[] {
		const { selectedBranchValueIds } = LovUtil.rectifySelectedValueIds(templateIds, this.rightsetTemplateHLC);
		return selectedBranchValueIds.map(id => this.rightsetTemplateHLC.valueIdLabelMap.get(id));
	}


	private dateSystemIndicators: number[] = [SystemIndicators.RightsStartDate, SystemIndicators.RightsEndDate];
	private dimensionSystemIndicators: number[] = [SystemIndicators.Media, SystemIndicators.Territory, SystemIndicators.Language, SystemIndicators.Channel];
	private exclusiveSystemIndicator = SystemIndicators.Exclusive;

	getValueForSystemIndicator(availSnapshot: AvailsSnapshot, systemIndicator: number) {
		switch (systemIndicator) {
			case SystemIndicators.Media: {
				return availSnapshot.dim1BranchIds;
			}
			case SystemIndicators.Territory: {
				return availSnapshot.dim2BranchIds;
			}
			case SystemIndicators.Language: {
				return availSnapshot.dim3BranchIds;
			}
			case SystemIndicators.Channel: {
				return availSnapshot.dim4BranchIds;
			}
			case SystemIndicators.RightsStartDate: {
				return availSnapshot.termStart;
			}
			case SystemIndicators.RightsEndDate: {
				return availSnapshot.termEnd;
			}
			case SystemIndicators.Exclusive: {
				return availSnapshot.exclusivity;
			}
			default: return null;
		}
	}

	getCharDatas(rightsTemplateId: number, charMetaData: ICharacteristicMetaDataCollection, avails: AvailsSnapshot[]): ITemplateCharData[] {
		const metaData = this._oneConfig.getTemplateMetaData(CharTypeId.Right, rightsTemplateId);
		const charDatas: ITemplateCharData[] = [];

		avails.forEach((a) => {
			const charData: ICharacteristicData[] = [];
			charMetaData.characteristicMetaDatas.forEach((x) => {

				const val = this.getValueForSystemIndicator(a, x.systemIndicator);

				// Currently all potential values are LOV's or a Date
				if (x.charValueSourceID) {
					const lovMetaData = this._oneConfig.getLovMetaData(x.charValueSourceID);
					if (this.dimensionSystemIndicators.indexOf(x.systemIndicator) > -1) {
						const hlc = LovUtil.buildHierarchyLabelContext(lovMetaData.listOfValues);
						if (isArray(val)) {
							val.forEach(v => {
								charData.push({
									charactersticID: x.characteristicID,
									recordCharacteristicID: null,
									value: hlc.valueIdLabelMap.get(v),
									valueID: v
								})
							})
						}
					} else if (this.exclusiveSystemIndicator == x.systemIndicator) {
						const exclusive = lovMetaData.listOfValues.find(x => x.characteristicValueLabel == "Exclusive");
						const nonExclusive = lovMetaData.listOfValues.find(x => x.characteristicValueLabel == "Non-Exclusive");

						if (val === true) {
							charData.push({
								charactersticID: x.characteristicID,
								recordCharacteristicID: null,
								value: exclusive.characteristicValueLabel,
								valueID: exclusive.characteristicValueID
							})
						} else if (val === false) {
							charData.push({
								charactersticID: x.characteristicID,
								recordCharacteristicID: null,
								value: nonExclusive.characteristicValueLabel,
								valueID: nonExclusive.characteristicValueID
							})
						}
					}
				} else if (this.dateSystemIndicators.indexOf(x.systemIndicator) > -1) {
					const parsed = DateUtil.parseToUTCMoment(val.toString(), DateLocaleType.Storage);
					charData.push({
						charactersticID: x.characteristicID,
						recordCharacteristicID: null,
						value: DateUtil.formatUTCMomentAsDate(parsed, DateLocaleType.Storage),
						valueID: null
					})
				}
			});

			charDatas.push({
				charDatas: charData,
				template: metaData,
				assocChanges: {
					"1": {
						charTypeId: CharTypeId.Property,
						current: [
							{
								charTypeId: CharTypeId.Property,
								recordId: a.catalogId,
								title: a.catalogTitle,
								templateId: 0
							}
						],
						selectState: {
							defaultSelectedIds: [],
							gridCount: 0,
							gridSelected: null,
							inputListSelected: [],
							isValid: true,
							labelFn: null,
							model: null,
							netChanges: {
								addedIds: new Set([a.catalogId]),
								addedValues: null,
								isAllDeselected: false,
								isAllSelected: false,
								deletedIds: new Set([]),
								deletedValues: null
							},
							selectedCount: 0,
							selectedIds: [a.catalogId],
							selectedValues: []
						}
					}
				}
			});
		});

		return charDatas;
	}


	getAvailableVerbiage(availStatusId: number): string {
		switch (availStatusId) {
			case AvailStatusIds.available:
				return "Fully";
			case AvailStatusIds.partial:
				return "Partially";
			case AvailStatusIds.unavailable:
				return "Unavailable";
			default:
				return "";
		}
	}
}
